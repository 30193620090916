import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import withHydrationOnDemand from "react-hydration-on-demand";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  hideHeader,
  setMarginTop,
  showHeader,
} from "../../helpers/customScripts";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import { convertInputIdToValues } from "../../shared/components/Input/StyledInput/StyledInput.methods";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import SearchIcon from "../../shared/icons/SearchIcon/SearchIcon";
import { IBoxSearchBarProps } from "./BoxSearchBar.types";
import SearchBar from "./elements/SearchBar";

const BoxSearchBar: React.FC<IBoxSearchBarProps> = ({
  settings,
  publicationRef,
}: IBoxSearchBarProps) => {
  const isMobile = useIsMobilePortal();
  const router = useRouter();

  const [expandSearchBar, setExpandSearchBar] = useState(false);

  const hideSearchBar = () => {
    setExpandSearchBar(false);
  };

  const theme = useTheme() as IThemeState;
  const inputSettings = convertInputIdToValues(
    settings.search_bar_search_input_settings,
    theme.globalObjects?.inputs
  );

  useEffect(() => {
    if (!isMobile) return;
    setExpandSearchBar(false);
    showHeader();
    setMarginTop(0);

    return () => {
      setMarginTop(0);
      showHeader();
    };
  }, [router.asPath]);

  useEffect(() => {
    if (expandSearchBar) {
      setMarginTop(inputSettings.height);
    } else {
      setMarginTop(0);
      showHeader();
    }
  }, [expandSearchBar]);

  if (!isMobile || (expandSearchBar && isMobile)) {
    return (
      <SearchBar
        expandSearchBar={expandSearchBar}
        hideSearchBar={hideSearchBar}
        publicationRef={publicationRef}
      />
    );
  } else {
    return (
      <button
        className="search-bar-container__enable-mobile-search-bar"
        onClick={() => {
          setExpandSearchBar(true);
          isMobile && hideHeader();
        }}
        data-testid="search"
      >
        <SearchIcon
          width={
            settings.search_bar_mobile_loupe_size
              ? settings.search_bar_mobile_loupe_size
              : "24"
          }
          height={
            settings.search_bar_mobile_loupe_size
              ? settings.search_bar_mobile_loupe_size
              : "24"
          }
          fill={convertColorIdToHex(
            settings.search_bar_mobile_loupe_color,
            theme.colorPalette
          )}
        />
      </button>
    );
  }
};

export default withHydrationOnDemand({ on: ["visible"] })(BoxSearchBar);
